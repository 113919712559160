import React, { useState } from "react";
import { Container, Row, Col, Button, Alert, Spinner } from "react-bootstrap";
import { useAuth } from "../components/auth/AuthProvider";
import { ViewImagesModal } from "../components/modal/ViewImagesModal";
import { CameraList } from "../layouts/CameraList";
import { useObjectData } from "../components/utils/useObjectData";
import { CameraModal } from "../components/modal/CameraModal";
import { addCamera, updateCamera } from "../components/utils/cameraService";
import { useCsrfToken } from "../components/utils/useCsrfToken";

export const CameraPage = () => {
  const { user } = useAuth();
  const { csrfToken, csrfLoading, csrfError } = useCsrfToken();
  const [showViewImagesModal, setShowViewImagesModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showCameraModal, setShowCameraModal] = useState(false);
  const [refreshCameraList, setRefreshCameraList] = useState(0);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const initialCameraState = {
    id: "",
    camera_name: "",
    camera_number: "",
    latitude: "",
    longitude: "",
    active: false,
    sim_id: "",
    phone_number: "",
    manufacturer_id: "",
    location_id: "",
    building_name: "",
    building_number: "",
  };
  const {
    objectData: cameraData,
    setObjectData: setCameraData,
    handleChange,
  } = useObjectData(initialCameraState);

  // Handlers
  const handleViewImages = (camera) => {
    setCameraData(camera);
    setShowViewImagesModal(true);
  };

  const handleEditCamera = (camera) => {
    setCameraData(camera);
    setIsEditing(true);
    setShowCameraModal(true);
  };

  const handleAddCamera = () => {
    setIsEditing(false);
    setShowCameraModal(true);
  };

  const onCameraUpdate = () => {
    setRefreshCameraList((prev) => prev + 1); // Toggle the refresh state
  };

  const handleModalClose = () => {
    setShowCameraModal(false);
    setCameraData(initialCameraState);
    setAlertMessage("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let response;
      if (isEditing) {
        response = await updateCamera(csrfToken, cameraData);
        setAlertMessage("Camera updated successfully!");
      } else {
        response = await addCamera(csrfToken, cameraData);
        setAlertMessage("Camera added successfully!");
      }

      onCameraUpdate();
      handleModalClose();
    } catch (error) {
      setAlertMessage(error.message || "Failed to complete operation.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fluid className="camerapage">
      {csrfLoading && <Spinner animation="border" />}
      {csrfError && <Alert variant="danger">{csrfError}</Alert>}
      <Row className="mt-3">
        <Col>
          <h1>Camera Management</h1>
        </Col>
      </Row>

      <Row>
        <Col className="d-flex justify-content-end align-items-center">
          {user?.role === "admin" && (
            <Button variant="primary" onClick={handleAddCamera}>
              Add Camera
            </Button>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <CameraList
            onEditCamera={handleEditCamera}
            onViewImages={handleViewImages}
            refresh={refreshCameraList}
          />
        </Col>
        {/* View Images Modal */}
        <ViewImagesModal
          show={showViewImagesModal}
          handleClose={() => setShowViewImagesModal(false)}
          cameraId={cameraData.id}
        />

        <CameraModal
          show={showCameraModal}
          title={isEditing ? "Edit Camera" : "Add Camera"}
          handleClose={handleModalClose}
          onSubmit={handleSubmit}
          message={alertMessage}
          cameraData={cameraData}
          setCameraData={setCameraData}
          handleChange={handleChange}
          isEditing={isEditing}
          loading={loading}
        />
      </Row>
    </Container>
  );
};
