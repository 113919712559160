import { useEffect, useState, useCallback, useRef } from "react";

export const useFetchData = (url, refreshInterval = null) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Use a ref to keep track of the current AbortController
  const controllerRef = useRef(null);
  const intervalIdRef = useRef(null);

  const fetchData = useCallback(
    async (abortController) => {
      // If there's an ongoing request, abort it
      if (controllerRef.current) {
        controllerRef.current.abort();
      }

      // Update the controllerRef to the new abort controller
      controllerRef.current = abortController;

      setLoading(true);
      setError(null);
      try {
        const response = await fetch(url, { signal: abortController.signal });

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
        const result = await response.json();

        setData(result);
        setError(null);
      } catch (err) {
        if (err.name !== "AbortError") {
          setError(err.message);
          setData(null);
        }
      } finally {
        setLoading(false);
        // Clean up the current controllerRef once the request is finished
        controllerRef.current = null;
      }
    },
    [url]
  );

  useEffect(() => {
    if (!url) {
      setLoading(false);
      return;
    }

    const controller = new AbortController();
    fetchData(controller);

    // Set up interval for refreshing data if refreshInterval is provided
    if (refreshInterval) {
      intervalIdRef.current = setInterval(() => {
        const newController = new AbortController();
        fetchData(newController);
      }, refreshInterval);
    }

    return () => {
      controller.abort(); // Abort fetch if component unmounts or URL changes
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current); // Clear the interval
      }
    };
  }, [url, refreshInterval, fetchData]);

  // Define refetchData to manually trigger the fetchData function
  const refetchData = useCallback(() => {
    const controller = new AbortController();
    fetchData(controller);
  }, [fetchData]);

  return { data, loading, error, refetchData };
};
