import React from "react";
import { useAuth } from "./AuthProvider";
import { Navigate, useLocation } from "react-router-dom";
import ErrorPage from "../../pages/ErrorPage";

/**
 * The ProtectedRoute component restricts access to its child components based on user authentication and roles.
 *
 * It performs the following checks:
 * - If the user is **not authenticated**, it redirects them to the login page.
 * - If the user is authenticated but does not have one of the **allowed roles**, it renders a 403 Forbidden error page.
 * - If the user passes all checks, it renders the child components.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Array<string>} [props.allowedRoles] - Optional array of roles allowed to access the route.
 * @param {React.ReactNode} props.children - The child components to render if access is granted.
 *
 * @returns {JSX.Element} The rendered component based on authentication and authorization checks.
 *
 * @example
 * // Usage example for an admin-only route:
 * <ProtectedRoute allowedRoles={['admin']}>
 *   <AdminDashboard />
 * </ProtectedRoute>
 */

export const ProtectedRoute = ({ allowedRoles, allowedClients, children }) => {
  const { isAuthenticated, user } = useAuth(); // Access the authentication state from the AuthProvider
  const location = useLocation(); // Get current location

  // If the user is not authenticated, render a 401 Unauthorized error page
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // Check for role-based access
  const hasRoleAccess = !allowedRoles || allowedRoles.includes(user?.role);

  // Check for client-based access if `allowedClients` is specified
  const hasClientAccess =
    !allowedClients || allowedClients.includes(user?.client);

  // If user lacks either role or client access, render a 403 Forbidden error page
  if (!hasRoleAccess || !hasClientAccess) {
    return <ErrorPage statusCode={403} />;
  }

  return children;
};
