import React, { useState, useEffect, useCallback } from "react";
import { Modal, Spinner, Alert } from "react-bootstrap";
import { CameraForm } from "../form/CameraForm";

export const CameraModal = ({
  show,
  title,
  handleClose,
  onSubmit,
  message,
  cameraData,
  setCameraData,
  handleChange,
  isEditing,
  loading,
}) => {
  const [selectedBuilding, setSelectedBuilding] = useState({});

  // Synchronize selectedBuilding with cameraData when modal opens or when cameraData changes
  useEffect(() => {
    if (cameraData && cameraData.location_id && cameraData.building_name) {
      setSelectedBuilding({
        id: cameraData.location_id,
        name: cameraData.building_name,
      });
    } else {
      setSelectedBuilding({});
    }
  }, [cameraData]);

  const handleBuildingChange = useCallback(
    (building) => {
      setSelectedBuilding(building);
      setCameraData((prevData) => ({
        ...prevData,
        location_id: building.id,
        building_name: building.name,
      }));
    },
    [setCameraData]
  );

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <Spinner animation="border" size="sm" />}
        {message && (
          <Alert
            variant={message.includes("successfully") ? "success" : "danger"}
          >
            {message}
          </Alert>
        )}
        <CameraForm
          cameraData={cameraData}
          handleChange={handleChange}
          onFormSubmit={onSubmit}
          selectedBuilding={selectedBuilding}
          handleBuildingChange={handleBuildingChange}
          isEditing={isEditing}
          loading={loading}
        />
      </Modal.Body>
    </Modal>
  );
};
