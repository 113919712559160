import React from "react";
import {
  Row,
  Col,
  Container,
  Spinner,
  Alert,
  Button,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import VisualIcon from "../components/visual/VisualIcon";
import {
  faBuildingColumns,
  faCamera,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { useFetchData } from "../components/utils/useFetchData";

/**
 * The RoofOverview component displays the total number of buildings and cameras.
 *
 * @component
 *
 */

export const RoofOverview = () => {
  const {
    data: activeCount,
    loading,
    error,
  } = useFetchData("/api/get_active/count");

  const {
    data: faultyCameras,
    loading: loadingFaulty,
    error: errorFaulty,
  } = useFetchData("/api/get_active/faulty_camera");

  // Define the Popover content for faulty cameras
  const faultyCameraPopover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Faulty Cameras</Popover.Header>
      <Popover.Body className="faulty-camera-list">
        {faultyCameras && faultyCameras.length > 0 ? (
          <ul>
            {faultyCameras.map((camera, index) => (
              <li key={index}>
                camera {camera.camera_number} at {camera.building_name}
              </li>
            ))}
          </ul>
        ) : (
          <div>No faulty cameras available</div>
        )}
      </Popover.Body>
    </Popover>
  );
  return (
    <Container>
      {/* Show loading state */}
      {loading && <Spinner animation="border" size="sm" />}

      {/* Show error state if there was an error */}
      {error && (
        <Alert variant="danger" className="mb-3">
          Error fetching data: {error}
        </Alert>
      )}

      {/* Display the data if it is successfully fetched */}
      {!loading && !error && activeCount && (
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            {/* Active Buildings Visual */}
            <VisualIcon
              icon={faBuildingColumns}
              activeCount={activeCount.buildings_with_active_cameras}
              color="#4682B4" // Steel blue color for buildings
              label="Active Buildings"
            />
          </Col>
          <Col className="d-flex justify-content-center align-items-center">
            {/* Active Cameras Visual */}
            <VisualIcon
              icon={faCamera}
              activeCount={activeCount.active_cameras}
              color="#775C40" // Firebrick color for cameras
              label="Active Cameras"
            />
          </Col>
          <Col className="d-flex justify-content-center align-items-center">
            {/* Faulty Cameras Button - Conditionally rendered next to the camera icon */}
            {!loadingFaulty &&
              !errorFaulty &&
              faultyCameras &&
              faultyCameras.length > 0 && (
                <OverlayTrigger
                  trigger="click"
                  placement="right"
                  overlay={faultyCameraPopover}
                >
                  <Button
                    variant="link"
                    className="ms-3"
                    style={{ textDecoration: "none" }}
                  >
                    <VisualIcon
                      icon={faTriangleExclamation}
                      activeCount={faultyCameras.length}
                      color="#FFA500" // Orange color for faulty cameras
                      label=""
                    />
                  </Button>
                </OverlayTrigger>
              )}
          </Col>
        </Row>
      )}
    </Container>
  );
};
