import { useState, useEffect } from "react";

export const useHandleDataPull = (csrfToken, refetchData, refreshInterval) => {
  const [pullingData, setPullingData] = useState(false);

  const handleDataPull = async () => {
    setPullingData(true);
    try {
      const response = await fetch("/api/audits/update_data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to initiate data pull.");
      }

      await refetchData();
    } catch (error) {
      alert("Error initiating data pull: " + error.message);
    } finally {
      setPullingData(false);
    }
  };

  // Setup interval for refreshing data
  useEffect(() => {
    if (csrfToken) {
      const intervalId = setInterval(() => {
        handleDataPull();
      }, refreshInterval);

      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [csrfToken, refreshInterval]);

  return { handleDataPull, pullingData };
};
