import React from "react";
import { Table, Button, Spinner } from "react-bootstrap";

export const UserTable = ({
  users,
  loading,
  fetchError,
  onEditUser,
  onDeleteUser,
}) => {
  if (fetchError) {
    return <div>Error fetching users: {fetchError}</div>;
  }

  return (
    <Table className="usertable" striped bordered hover responsive>
      <thead>
        <tr>
          <th>Username</th>
          <th>Email</th>
          <th>Client</th>
          <th>Access Type</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan="6" className="text-center">
              <Spinner animation="border" size="sm" />
            </td>
          </tr>
        ) : (
          users.map((user) => (
            <tr key={user.id}>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>{user.client_name}</td>
              <td>{user.role}</td>
              <td>
                <Button variant="warning" onClick={() => onEditUser(user)}>
                  Edit
                </Button>
                <Button variant="danger" onClick={() => onDeleteUser(user)}>
                  Remove
                </Button>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
};
