import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { LoginForm } from "../components/form/LoginForm";

// Login page with login
export const LoginPage = () => {
  return (
    <Container className="loginpage">
      <Row className="justify-content-md-center mt-5">
        <Col md={4}>
          <LoginForm />
        </Col>
      </Row>
    </Container>
  );
};
