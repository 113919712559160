import React from "react";
import { Modal, Button } from "react-bootstrap";

/**
 * The JobLinkModal component displays a modal dialog with a link to the newly created job.
 *
 * It shows a success message and provides a hyperlink to view the job. The modal can be closed by the user.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Determines whether the modal is visible.
 * @param {function} props.handleClose - Function to handle the modal close action.
 * @param {string} props.jobUrl - The URL of the newly created job.
 *
 * @returns {JSX.Element} The rendered JobLinkModal component.
 *
 * @example
 * // Usage example:
 * <JobLinkModal
 *   show={showModal}
 *   handleClose={handleModalClose}
 *   jobUrl="https://example.com/job/123"
 * />
 */

export const JobLinkModal = ({ show, handleClose, jobUrl }) => (
  <Modal show={show} onHide={handleClose} centered>
    <Modal.Header closeButton>
      <Modal.Title>Job Created Successfully!</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        The job has been created successfully. You can view the job at the
        following link:
      </p>
      {jobUrl && (
        <a href={jobUrl} target="_blank" rel="noopener noreferrer">
          View Job
        </a>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);
