import React, { useState, useEffect, useCallback } from "react";
import { Modal, Spinner, Alert, Button } from "react-bootstrap";
import { UserForm } from "../form/UserForm";

export const UserModal = ({
  show,
  title,
  handleClose,
  onSubmit,
  message,
  userData,
  setUserData,
  handleChange,
  isEditing,
  isDelete,
  loading,
}) => {
  const [selectedClient, setSelectedClient] = useState({});

  // Synchronize selectedClient with userData when modal opens or when userData changes
  useEffect(() => {
    if (userData?.client_id && userData?.client_name) {
      setSelectedClient({
        id: userData.client_id,
        name: userData.client_name,
      });
    } else {
      setSelectedClient({});
    }
  }, [userData]);

  const handleClientChange = useCallback(
    (client) => {
      setSelectedClient(client);
      setUserData((prevData) => ({
        ...prevData,
        client_id: client.id,
        client_name: client.name,
      }));
    },
    [setUserData]
  );

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <Spinner animation="border" size="sm" />}
        {message && (
          <Alert
            variant={message.includes("successfully") ? "success" : "danger"}
          >
            {message}
          </Alert>
        )}
        {/* Conditional rendering based on isDelete prop */}
        {isDelete ? (
          <div>
            <p>Are you sure you want to delete this user?</p>
            <div className="d-flex justify-content-end">
              <Button
                variant="secondary"
                onClick={handleClose}
                className="me-2"
              >
                Cancel
              </Button>
              <Button variant="danger" onClick={onSubmit}>
                Confirm Delete
              </Button>
            </div>
          </div>
        ) : (
          <UserForm
            userData={userData}
            handleChange={handleChange}
            handleClientChange={handleClientChange}
            selectedClient={selectedClient}
            onFormSubmit={onSubmit}
            isEditing={isEditing}
            loading={loading}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};
