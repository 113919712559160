import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useRoofImages } from "./utils/useRoofImages";

/**
 * Renders a roof image component based on building or camera selection.
 *
 * @param {object} props - The props object.
 * @param {string} props.buildingId - The ID of the building to fetch images for.
 * @param {string} [props.cameraId] - The ID of the camera to fetch images for.
 * @param {function} props.onDataFetched - Callback to send fetched data to parent component.
 * @returns {JSX.Element} The roof image component.
 */

export const RoofImage = ({
  buildingId,
  cameraId,
  onDataFetched,
  onImageClick,
}) => {
  const { data, loading, hasMore, loadMore } = useRoofImages(
    buildingId,
    cameraId,
    onDataFetched
  );

  return (
    <Container className="mt-1" fluid>
      <Row>
        {data.map((item) => (
          <Col xl={4} lg={6} md={6} sm={12} className="pb-1" key={item.id}>
            <Card className="h-100">
              <Card.Body>
                <Card.Title>CAMERA {item.camera_number}</Card.Title>
              </Card.Body>
              <Card.Img
                variant="top"
                src={item.presigned_url}
                alt="Roof Image"
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
                onClick={() => onImageClick(item.presigned_url)}
              />
            </Card>
          </Col>
        ))}
      </Row>
      {cameraId && hasMore && !loading && (
        <div className="d-flex justify-content-center my-3">
          <Button onClick={loadMore} disabled={loading}>
            Load More
          </Button>
        </div>
      )}
      {loading && (
        <div className="d-flex justify-content-center my-3">
          <p>Loading...</p>
        </div>
      )}
    </Container>
  );
};
