import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Row, Col, Container } from "react-bootstrap";
import PropTypes from "prop-types";

const VisualIcon = ({ activeCount, ...props }) => {
  return (
    <Container className="text-center">
      <Row className="mt-4">
        <Col className="d-flex justify-content-center align-items-center">
          <div style={{ position: "relative", display: "inline-block" }}>
            {/* Display the building icon */}
            <FontAwesomeIcon size="8x" {...props} />
            {/* Overlay the number of active buildings on top of the icon */}
            <span
              style={{
                position: "absolute",
                top: "55%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "#ffffff",
                fontSize: "3rem",
                fontWeight: "bold",
                fontFamily: "sans-serif",
              }}
            >
              {activeCount}
            </span>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

VisualIcon.propTypes = {
  activeCount: PropTypes.number.isRequired,
};

export default VisualIcon;
