import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Button, Alert } from "react-bootstrap";
import { useAuth } from "../auth/AuthProvider";
import { useCsrfToken } from "../utils/useCsrfToken";

/**
 * The LoginForm component renders a login form with username and password fields.
 *
 * It handles user authentication by sending a login request to the server.
 * On successful login, it updates the authentication context and redirects the user
 * to the intended page or the home page. It also manages CSRF tokens to secure the login request.
 *
 * @component
 * @returns {JSX.Element} The rendered login form component.
 *
 * @example
 * // Use the LoginForm component in your application
 * <LoginForm />
 */

export const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();

  const { csrfToken, loading, error } = useCsrfToken();

  /**
   * Handles the login form submission.
   *
   * Sends a POST request to the server with the username and password.
   * If successful, updates the authentication context and redirects the user.
   * Handles errors by displaying appropriate messages.
   *
   * @async
   * @function handleLogin
   * @param {Event} event - The form submission event.
   * @returns {Promise<void>}
   */

  const handleLogin = async (event) => {
    event.preventDefault();
    setMessage("");

    if (loading) return; // Prevent form submission if CSRF token is still loading

    try {
      const response = await fetch("/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
        credentials: "include", // Include authentication cookies in the request
      });

      const result = await response.json();

      if (response.ok) {
        login(result.user);

        // Redirect based on client_id
        const client = result.user.client;
        let redirectPath;

        if (client === "monash university") {
          redirectPath = "/roof"; // Change to desired path for client 1
        } else {
          redirectPath = "/home"; // Default route if no specific client path
        }

        // Navigate to determined path or to intended route
        const from = location.state?.from?.pathname || redirectPath;
        navigate(from);
      } else {
        setMessage(result.message || "Login failed");
      }
    } catch (error) {
      setMessage("An error occurred while logging in.");
    }
  };

  if (error) {
    return (
      <Alert variant="danger" className="mt-5">
        Failed to load CSRF token:
        {error}
      </Alert>
    );
  }

  return (
    <div className="login-form">
      <Form onSubmit={handleLogin}>
        <Form.Group controlId="username" className="mb-3">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="password" className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Form.Group>

        {message && (
          <Alert variant="danger" className="mb-3">
            {message}
          </Alert>
        )}

        <Button variant="primary" type="submit" className="w-20 mb-2">
          Login
        </Button>
      </Form>
    </div>
  );
};
