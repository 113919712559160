import React from "react";
import { Row, Col, Button, Container, Form } from "react-bootstrap";
import { useAuth } from "../components/auth/AuthProvider";
import { useObjectData } from "../components/utils/useObjectData";
import { useCsrfToken } from "../components/utils/useCsrfToken";
import { updateUser } from "../components/utils/userService";

export const ProfilePage = () => {
  const { user, login } = useAuth();
  const { csrfToken } = useCsrfToken();

  const {
    objectData: userData,
    setObjectData: setUserData,
    handleChange,
  } = useObjectData({
    email: user?.email,
    username: user?.username,
    password: "",
    confirmPassword: "",
    client: user?.client,
    role: user?.role,
  });

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userData.password !== userData.confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    try {
      // Call updateUser with the CSRF token, user ID, and form data
      await updateUser(csrfToken, user.id, {
        ...userData,
        confirmPassword: undefined, // Do not send confirmPassword to the backend
      });

      // Update the user's email in the auth context after a successful update
      login({ ...user, email: userData.email });

      // Set the userData to refresh the form fields with updated data
      setUserData({
        ...userData,
        password: "", // Clear password fields after submission
        confirmPassword: "",
      });

      alert("Profile updated successfully!");
    } catch (error) {
      console.error("Failed to update profile:", error);
      alert(error.message || "An error occurred while updating the profile.");
    }
  };

  return (
    <Container className="profilepage">
      <Form onSubmit={handleSubmit}>
        <Form.Group
          as={Row}
          className="mb-2"
          controlId="formPlaintextUsernameClient"
        >
          <Col sm="6">
            <Form.Group as={Row} className="mb-2">
              <Form.Label column sm="4">
                Username:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  className="plaintext"
                  plaintext
                  readOnly
                  value={userData.username}
                />
              </Col>
            </Form.Group>
          </Col>

          <Col sm="6">
            <Form.Group
              as={Row}
              className="mb-2"
              controlId="formPlaintextClient"
            >
              <Form.Label column sm="4">
                Client:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  className="plaintext"
                  plaintext
                  readOnly
                  value={userData.client}
                />
              </Col>
            </Form.Group>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-2">
          <Col sm="6">
            <Form.Group
              as={Row}
              className="mb-2"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="4">
                Email:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={userData.email}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Form.Group>
          </Col>

          <Col sm="6">
            <Form.Group as={Row} className="mb-2" controlId="formPlaintextRole">
              <Form.Label column sm="4">
                Role:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  className="plaintext"
                  plaintext
                  readOnly
                  value={userData.role}
                />
              </Col>
            </Form.Group>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-2">
          <Col sm="6">
            <Form.Group as={Row} className="mb-2">
              <Form.Label column sm="4">
                New Password:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="password"
                  placeholder="Please enter new password"
                  name="password"
                  value={userData.password}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-2">
              <Form.Label column sm="4">
                Confirm Password:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="password"
                  placeholder="Confirm new password"
                  name="confirmPassword"
                  value={userData.confirmPassword}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
          </Col>
        </Form.Group>

        <Row className="mt-3">
          <Col className="d-flex justify-content-center">
            <Button type="submit">Update Profile</Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};
