import React from "react";
import Plot from "react-plotly.js";
import PropTypes from "prop-types";

/**
 * Renders a line chart component.
 *
 * @param {Array} timestamps - An array of timestamps to be used on the x-axis.
 * @param {Array} values - An array of values to be used on the y-axis.
 * @returns {JSX.Element} The line chart component.
 */

export const LineChart = ({ timestamps, values, layout = {} }) => {
  return (
    <Plot
      className="linechart"
      data={[
        {
          x: timestamps,
          y: values,
          type: "scatter",
          mode: "lines+markers+text",
          marker: { color: "blue" },
          text: values,
          textposition: "top center",
          textfont: {
            color: "white",
            // size: 10,
          },
        },
        {
          type: "indicator",
          mode: "number+delta",
          value: values[values.length - 1],
          delta: {
            reference: values[values.length - 2],
            position: "bottom",
            increasing: { color: "green" },
            decreasing: { color: "red" },
          },
          domain: { x: [0, 1], y: [0, 1] },
          showarrow: true,
          number: { font: { color: "white" } },
        },
      ]}
      layout={{
        ...layout,
        title: {
          text: layout?.title || "",
        },
        xaxis: {
          title: layout?.xaxis?.title || "",
          ...layout?.xaxis,
        },
        yaxis: {
          title: layout?.yaxis?.title || "",
          ...layout?.yaxis,
        },
        plot_bgcolor: layout?.plot_bgcolor || "#000000",
        paper_bgcolor: layout?.paper_bgcolor || "#000000",
      }}
    />
  );
};

LineChart.propTypes = {
  timestamps: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  layout: PropTypes.object,
};
