import { useEffect, useState } from "react";

/**
 * Custom hook to fetch and manage the CSRF token state.
 *
 * This hook provides the CSRF token, loading status, and any error that occurs during the fetch.
 * It is typically used to include the CSRF token in requests for enhanced security.
 *
 * @returns {Object} The returned object contains:
 * - `csrfToken` (string|null): The fetched CSRF token, or `null` if not yet available.
 * - `csrfLoading` (boolean): Whether the CSRF token is currently being fetched.
 * - `csrfError` (string|null): Any error message that occurred during the fetch.
 *
 * @example
 * const { csrfToken, csrfLoading, csrfError } = useCsrfToken();
 * if (csrfLoading) {
 *   return <div>Loading CSRF token...</div>;
 * }
 * if (csrfError) {
 *   return <div>Error: {csrfError}</div>;
 * }
 */

export const useCsrfToken = () => {
  const [csrfToken, setCsrfToken] = useState(null);
  const [csrfLoading, setLoading] = useState(true);
  const [csrfError, setError] = useState(null);

  useEffect(() => {
    /**
     * Fetches the CSRF token from the server.
     *
     * Sends a request to `/auth/csrf-token` to get the CSRF token, and updates the state accordingly.
     *
     * @async
     * @function fetchCsrfToken
     * @returns {Promise<void>}
     */
    const fetchCsrfToken = async () => {
      try {
        const response = await fetch("/auth/csrf-token", {
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch CSRF token");
        }
        const data = await response.json();
        setCsrfToken(data.csrf_token);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchCsrfToken();
  }, []);

  return { csrfToken, csrfLoading, csrfError };
};
