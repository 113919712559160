import React from "react";
import { Form } from "react-bootstrap";

export const CameraSearchBar = ({ searchQuery, setSearchQuery }) => {
  const handleSearchChange = (e) => setSearchQuery(e.target.value);

  return (
    <Form.Group controlId="cameraList">
      <Form.Control
        type="text"
        placeholder="Search by Camera ID, Name, or Location"
        className="mb-3"
        value={searchQuery}
        onChange={handleSearchChange}
      />
    </Form.Group>
  );
};
