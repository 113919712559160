export const addCamera = async (csrfToken, cameraData) => {
  const response = await fetch("/api/add_camera", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(cameraData),
  });

  if (!response.ok) {
    const errorMessage = await response.json();
    throw new Error(errorMessage.error || "Failed to add camera");
  }

  return response.json();
};

export const updateCamera = async (csrfToken, cameraData) => {
  const response = await fetch(`/api/edit_camera/${cameraData.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(cameraData),
  });

  if (!response.ok) {
    const errorMessage = await response.json();
    throw new Error(errorMessage.error || "Failed to update camera");
  }

  return response.json();
};
