import React from "react";
import { Table, Button, Spinner } from "react-bootstrap";

export const CameraTable = ({
  cameras,
  loading,
  fetchError,
  onEditCamera,
  onViewImages,
}) => {
  if (fetchError) {
    return <div>Error fetching cameras: {fetchError}</div>;
  }

  return (
    <Table className="cameratable" striped bordered hover responsive>
      <thead>
        <tr>
          <th>Camera ID</th>
          <th>Status</th>
          <th>Camera Name</th>
          <th>Camera No.</th>
          <th>Location</th>
          <th>Last Active</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan="6" className="text-center">
              <Spinner animation="border" size="sm" />
            </td>
          </tr>
        ) : cameras?.length > 0 ? (
          cameras.map((camera) => (
            <tr key={camera.id}>
              <td>{camera.id}</td>
              <td>
                <span
                  className={`status-indicator ${
                    camera.active ? "active" : "inactive"
                  }`}
                >
                  {camera.active ? "Active" : "Inactive"}
                </span>
              </td>
              <td>{camera.camera_name}</td>
              <td>{camera.camera_number}</td>
              <td>
                {camera.location_id
                  ? `${camera.building_name} bld ${camera.building_number}`
                  : "Not Assigned"}
              </td>
              <td>
                {camera.last_active
                  ? new Intl.DateTimeFormat("en-AU", {
                      year: "numeric",
                      month: "2-digit",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      hour12: false,
                    }).format(new Date(camera.last_active))
                  : "No Data"}
              </td>
              <td>
                <Button variant="info" onClick={() => onViewImages(camera)}>
                  View Images
                </Button>
                <Button variant="warning" onClick={() => onEditCamera(camera)}>
                  Edit
                </Button>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="6" className="text-center">
              No cameras available
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};
