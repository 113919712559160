import React, { useEffect, useState } from "react";
import { LineChart } from "../components/visual/LineChart.jsx";
import { useFetchData } from "../components/utils/useFetchData.jsx";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { Container } from "react-bootstrap";

export const AuditChart = ({ refreshInterval }) => {
  const {
    data: auditChartData,
    loading: auditChartLoading,
    error: auditChartError,
  } = useFetchData("/api/audit_chart_data", refreshInterval);

  const [timestamps, setTimestamps] = useState([]);
  const [values, setValues] = useState([]);

  useEffect(() => {
    if (auditChartData && auditChartData.length > 0) {
      setTimestamps(auditChartData.map((entry) => entry.month));
      setValues(auditChartData.map((entry) => entry.audit_count));
    }
  }, [auditChartData]);

  const customLayout = {
    xaxis: {
      tickformat: "%b",
      dtick: "M1",
      tickfont: {
        color: "#ffffff", // X-axis tick labels color
      },
    },
    yaxis: {
      title: {
        text: "Quantity",
        font: {
          color: "#FF00FF", // Y-axis title color
        },
      },

      tickfont: {
        color: "#ffffff", // Y-axis tick labels color
      },
    },
    margin: { t: 0, b: 20 },
    height: 400,
  };

  return (
    <Container fluid>
      {auditChartLoading && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      {auditChartError && (
        <Alert variant="danger">Error: {auditChartError}</Alert>
      )}
      {timestamps.length > 0 && values.length > 0 && (
        <LineChart
          timestamps={timestamps}
          values={values}
          layout={customLayout}
        />
      )}
    </Container>
  );
};
