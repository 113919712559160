import React, { useState } from "react";
import { RoofByBuilding } from "../layouts/RoofByBuilding";
import { RoofOverview } from "../layouts/RoofOverview";
import { Button, Row, Col, Container } from "react-bootstrap";
import { useAuth } from "../components/auth/AuthProvider";
import { useCsrfToken } from "../components/utils/useCsrfToken";

/**
 * The RoofPage component renders the roof management interface,
 * allowing users to switch between an overview of all roofs and
 * a detailed view by building. It handles user authentication and
 * CSRF token retrieval to secure API requests.
 *
 * @component
 * @returns {JSX.Element} The rendered RoofPage component.
 *
 * @example
 * // Renders the RoofPage component within the application
 * <RoofPage />
 */

export const RoofPage = () => {
  const { user } = useAuth(); // Get the authenticated user
  const { csrfToken, csrfError } = useCsrfToken();

  // State to toggle between overview and building view
  const [viewMode, setViewMode] = useState("overview"); // 'overview' or 'building'

  /**
   * Switches the view to "overview" mode.
   *
   * @function
   * @returns {void}
   */

  // Switch to overview mode
  const handleShowOverview = () => setViewMode("overview");

  /**
   * Switches the view to "building" mode.
   *
   * @function
   * @returns {void}
   */

  // Switch to building selection mode
  const handleShowBuildingView = () => setViewMode("building");

  if (csrfError) {
    return <div>Error loading CSRF token: {csrfError}</div>;
  }

  return (
    <Container fluid className="roofpage">
      {/* <Header /> */}
      <Row className="mt-3">
        <Col className="d-flex justify-content-center align-items-center">
          <h1>Intelligent Roof Maintenance</h1>
        </Col>
      </Row>
      {/* Buttons component */}
      <Row className="mt-2 justify-content-center">
        <Col className="d-flex justify-content-center flex-wrap">
          <Button
            variant={viewMode === "overview" ? "primary" : "outline-secondary"}
            onClick={handleShowOverview}
          >
            Overview
          </Button>
          <Button
            variant={viewMode === "building" ? "primary" : "outline-secondary"}
            className="ml-2"
            onClick={handleShowBuildingView}
          >
            View by Building
          </Button>
        </Col>
      </Row>
      <Row className="mt-2 justify-content-center">
        <Col className="d-flex flex-column justify-content-center align-items-center">
          {/* Render the selected view */}
          {viewMode === "overview" ? (
            <RoofOverview />
          ) : (
            <RoofByBuilding user={user} csrfToken={csrfToken} />
          )}
        </Col>
      </Row>
    </Container>
  );
};
