import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export const LandingPage = () => {
  return (
    <Container className="landingpage">
      <Row>
        <Col>
          <h1>Welcome to the Rooftop Ranger</h1>
          <p>
            This app is designed to help you manage and analyze rooftop images
            and data to make informed decisions.
          </p>
        </Col>
      </Row>
    </Container>
  );
};
