import React, { useState, useEffect } from "react";
import { AuditChart } from "../layouts/AuditChart";
import { AuditTable } from "../layouts/AuditTable";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useFetchData } from "../components/utils/useFetchData.jsx";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCsrfToken } from "../components/utils/useCsrfToken.jsx";
import { useHandleDataPull } from "../components/utils/useHandleDataPull.jsx";

const refreshInterval = 4 * 60 * 60 * 1000;

// Define target values manually
const safetyTarget = {
  "university of melbourne-parkville": 8,
  "university of melbourne-others": 2,
  "monash university-clayton": 8,
  "monash university-caulfield": 2,
  "monash university-others": 1,
  "deakin university-burwood": 4,
  "deakin university-waurn ponds": 4,
  "melbourne polytechnic-melbourne polytechnic": 4,
  "others-others": 1,
  // Add other campuses and clients as needed
};

const postjobTarget = {
  "university of melbourne-parkville": 8,
  "university of melbourne-others": 1,
  "monash university-monash": 5,
  "deakin university-burwood": 2,
  "deakin university-waurn ponds": 1,
  "melbourne polytechnic-melbourne polytechnic": 2,
  "others-others": 1,
};

const roofTarget = {
  "-": 5,
};
export const AuditPage = () => {
  // Fetch audit data from API and populate the table

  const {
    data: auditData,
    loading: auditLoading,
    error: auditError,
    refetchData,
  } = useFetchData("/api/audit_table_data", refreshInterval);

  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const { csrfToken, csrfLoading, csrfError } = useCsrfToken();
  const { handleDataPull, pullingData } = useHandleDataPull(
    csrfToken,
    refetchData,
    refreshInterval
  );

  useEffect(() => {
    // Set the last update time whenever new data is successfully fetched
    if (auditData) {
      setLastUpdateTime(
        new Date().toLocaleString("en-AU", {
          timeZone: "Australia/Melbourne",
        })
      );
    }
  }, [auditData]);

  // Handle CSRF loading or errors
  if (csrfLoading) {
    return <div>Loading CSRF token...</div>;
  }

  if (csrfError) {
    return <div>Error loading CSRF token: {csrfError}</div>;
  }

  const groupedByTemplate = auditData
    ? auditData.reduce((acc, audit) => {
        const { template_name } = audit;
        if (!acc[template_name]) {
          acc[template_name] = [];
        }
        acc[template_name].push(audit);

        return acc;
      }, {})
    : {};

  // Get data for each template type
  const safetyAudits = groupedByTemplate["safety"] || [];
  const postJobAudits = groupedByTemplate["postjob"] || [];
  const roofAudits = groupedByTemplate["roof"] || [];
  return (
    <Container fluid className="auditpage">
      <Row className="mt-3 pb-0">
        <Col className="d-flex justify-content-center">
          <h1>audits completed</h1>
        </Col>
        <Row>
          <Col>
            {lastUpdateTime && (
              <span className="lastupdate-text">
                Last Updated: {lastUpdateTime}
              </span>
            )}
            <Button
              className="refresh-btn"
              variant="secondary"
              onClick={handleDataPull}
              disabled={pullingData}
            >
              {pullingData ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <FontAwesomeIcon icon={faArrowsRotate} />
              )}
            </Button>
          </Col>
        </Row>
      </Row>
      <Row>
        <Col>
          {auditLoading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : auditError ? (
            <Alert variant="danger">Error: {auditError}</Alert>
          ) : (
            <AuditChart refreshInterval={refreshInterval} />
          )}
        </Col>
      </Row>
      {!auditLoading && !auditError && (
        <>
          <Row>
            <Col md={6}>
              <h2 style={{ color: "#6355D5" }}>site safety audits</h2>
              <AuditTable
                templateName="safety"
                audits={safetyAudits}
                targetValues={safetyTarget}
              />
            </Col>

            <Col md={6}>
              <h2 style={{ color: "#B1DB24" }}>post-job quality audits</h2>
              <AuditTable
                templateName="postjob"
                audits={postJobAudits}
                targetValues={postjobTarget}
              />
              <h2 style={{ color: "#EDB412" }}>roof audits</h2>
              <AuditTable
                templateName="roof"
                audits={roofAudits}
                targetValues={roofTarget}
              />
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};
