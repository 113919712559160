import React from "react";
import { Modal, Button } from "react-bootstrap";
import { RoofImage } from "../RoofImage";

export const ViewImagesModal = ({ show, handleClose, cameraId }) => {
  return (
    <Modal show={show} onHide={handleClose} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>View Images - Camera {cameraId}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RoofImage
          cameraId={cameraId}
          onImageClick={(imageUrl) => {
            // Handle image click if needed, for now, it could just be console logging the image URL
            console.log("Image clicked:", imageUrl);
          }}
        />
      </Modal.Body>
    </Modal>
  );
};
