import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import { AuthProvider } from "./src/components/auth/AuthProvider";

const root = createRoot(document.getElementById("app"));

// Wrap the App component with BrowserRouter to enable routing
root.render(
  <BrowserRouter>
    <AuthProvider>
      <App />
    </AuthProvider>
  </BrowserRouter>
);
