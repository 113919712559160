import React from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { SelectDropdown } from "../SelectDropdown";

/**
 * The CameraForm component provides a reusable form for adding or editing a camera.
 *
 * It includes input fields for camera details such as name, number, ID, coordinates,
 * and building location. It handles form submission by sending a POST or PUT request to the server,
 * manages CSRF tokens for security, and provides user feedback upon success or failure.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.cameraData - The camera data to be edited or initial empty state for adding.
 * @param {function} props.setCameraData - Function to update camera data in state.
 * @param {function} props.handleSubmit - Function to handle form submission.
 * @param {boolean} props.loading - Indicates if the form submission is in progress.
 * @param {Object} props.selectedBuilding - The selected building object.
 * @param {function} props.handleBuildingChange - Function to handle building selection change.
 * @param {boolean} props.isEditing - Indicates if the form is for editing (true) or adding (false).
 * @returns {JSX.Element} The rendered CameraForm component.
 *
 * @example
 * // Use the CameraForm component in your application
 * <CameraForm
 *   cameraData={cameraData}
 *   setCameraData={setCameraData}
 *   handleSubmit={handleSubmit}
 *   loading={loading}
 *   selectedBuilding={selectedBuilding}
 *   handleBuildingChange={handleBuildingChange}
 *   isEditing={true}
 * />
 */

export const CameraForm = ({
  cameraData,
  handleChange,
  onFormSubmit,
  selectedBuilding,
  handleBuildingChange,
  isEditing,
  loading,
}) => {
  return (
    <Container className="camera-form">
      <Form onSubmit={onFormSubmit}>
        <Row>
          {!isEditing && (
            <Form.Group as={Col} className="mb-2" controlId="camId">
              <Form.Label>Cam ID (RPS Asset No.)</Form.Label>
              <Form.Control
                type="text"
                name="id"
                placeholder="Cam ID"
                value={cameraData.id || ""}
                onChange={handleChange}
                required
              />
            </Form.Group>
          )}

          <Form.Group as={Col} className="mb-2" controlId="cameraNumber">
            <Form.Label>Camera Number</Form.Label>
            <Form.Control
              type="number"
              name="camera_number"
              placeholder="Camera Number"
              value={cameraData.camera_number || ""}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Form.Group as={Col} className="mb-2" controlId="cameraName">
          <Form.Label>Camera Name (e.g B050C02U2502)</Form.Label>
          <Form.Control
            type="text"
            name="camera_name"
            placeholder="Camera Name"
            value={cameraData.camera_name || ""}
            onChange={handleChange}
          />
        </Form.Group>

        <Row>
          <Form.Group as={Col} className="mb-2" controlId="latitude">
            <Form.Label>Latitude</Form.Label>
            <Form.Control
              type="text"
              name="latitude"
              placeholder="Latitude"
              value={cameraData.latitude || ""}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group as={Col} className="mb-2" controlId="longitude">
            <Form.Label>Longitude</Form.Label>
            <Form.Control
              type="text"
              name="longitude"
              placeholder="Longitude"
              value={cameraData.longitude || ""}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Form.Group className="mb-2" controlId="location">
          <Form.Label>Select Location</Form.Label>
          <SelectDropdown
            source="building"
            value={
              selectedBuilding.id
                ? { value: selectedBuilding.id, label: selectedBuilding.name }
                : { value: null, label: "Select building" }
            }
            allowNotAssigned={true}
            onChange={handleBuildingChange}
          />
        </Form.Group>

        <Row>
          <Form.Group as={Col} className="mb-2" controlId="simId">
            <Form.Label>SIM ID</Form.Label>
            <Form.Control
              type="text"
              name="sim_id"
              placeholder="SIM ID"
              value={cameraData.sim_id || ""}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group as={Col} className="mb-2" controlId="phoneNumber">
            <Form.Label>SIM Phone No.</Form.Label>
            <Form.Control
              type="text"
              name="phone_number"
              placeholder="Phone No."
              value={cameraData.phone_number || ""}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Form.Group className="mb-2" controlId="manufacturerId">
          <Form.Label>Manufacturer ID</Form.Label>
          <Form.Control
            type="text"
            name="manufacturer_id"
            placeholder="Manufacturer ID"
            value={cameraData.manufacturer_id || ""}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group controlId="active" className="mb-2">
          <Form.Check
            type="checkbox"
            label="Active"
            name="active"
            checked={cameraData.active || false}
            onChange={handleChange}
          />
        </Form.Group>

        <Button variant="primary" type="submit" disabled={loading}>
          {loading ? "Loading..." : isEditing ? "Update Camera" : "Add Camera"}
        </Button>
      </Form>
    </Container>
  );
};
