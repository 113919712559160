import React from "react";
import { Modal, Image, Button } from "react-bootstrap";

export const RoofImageModal = ({ show, handleClose, imageUrl }) => {
  return (
    <Modal
      show={show}
      //   fullscreen={true}
      onHide={handleClose}
      size="xl"
      centered
    >
      <Modal.Body className="text-center">
        {imageUrl ? (
          <Image src={imageUrl} alt={`Roof Image`} fluid />
        ) : (
          <p>No roof image available</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
