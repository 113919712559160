import React, { useEffect, useState } from "react";
import { Row, Col, Spinner, Button } from "react-bootstrap";
import { CameraSearchBar } from "../layouts/CameraSearchBar";
import { useFetchData } from "../components/utils/useFetchData";
import { CameraTable } from "../layouts/CameraTable";

export const CameraList = ({ onEditCamera, onViewImages, refresh }) => {
  const [page, setPage] = useState(1);
  const limit = 10;
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);

  const {
    data: fetchedCameraData,
    loading: fetchingCameraData,
    error: fetchError,
    refetchData,
  } = useFetchData(
    `/api/get_cameras?page=${page}&limit=${limit}&query=${encodeURIComponent(
      debouncedQuery
    )}`
  );

  // Effect for debouncing the search query input
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchQuery); // Update debounced query after delay
    }, 300); // Delay of 300ms

    // Cleanup timeout if searchQuery changes before delay is over
    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]); // Runs every time searchQuery changes

  // Extract cameras and total count from the fetched data
  const cameras = fetchedCameraData?.cameras || [];
  const totalCount = fetchedCameraData?.total_count || 0;

  // Refetch data when the page or search query changes
  useEffect(() => {
    refetchData();
  }, [page, debouncedQuery, refetchData]);

  // Refetch data when the refresh prop changes
  useEffect(() => {
    if (refresh > 0) {
      refetchData();
    }
  }, [refresh, refetchData]);

  const handleNextPage = () => {
    if (page * limit < totalCount) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <>
      <Row className="mt-3">
        <Col>
          <CameraSearchBar
            searchQuery={searchQuery}
            setSearchQuery={(query) => {
              setSearchQuery(query);
              setPage(1); // Reset page to 1 whenever search query changes
            }}
          />
          {fetchingCameraData && (
            <Spinner animation="border" variant="primary" />
          )}
          <CameraTable
            cameras={cameras}
            loading={fetchingCameraData}
            fetchError={fetchError}
            onEditCamera={onEditCamera}
            onViewImages={onViewImages}
          />
        </Col>
      </Row>
      {/* Pagination Controls */}
      <Row className="mt-3">
        <Col className="d-flex justify-content-between align-items-center">
          <Button
            variant="secondary"
            onClick={handlePreviousPage}
            disabled={page === 1}
          >
            Previous
          </Button>
          <span>Page {page}</span>
          <Button
            variant="primary"
            onClick={handleNextPage}
            disabled={page * limit >= totalCount || fetchingCameraData}
          >
            Next
          </Button>
        </Col>
      </Row>
    </>
  );
};
