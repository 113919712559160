import Table from "react-bootstrap/Table";
import React from "react";
import PropTypes from "prop-types";

const styles = {
  client: {
    color: "#0041FF",
    fontWeight: "bold",
  },
  campus: {
    color: "#B8860B",
  },
  targetAudits: {
    color: "#E1DD1E",
    fontWeight: "bold",
  },
  completed: {
    color: "#0EF136",
  },
  failed: {
    color: "#E66766",
  },
};

const renderTableHeader = (templateName, uniqueMonths) => (
  <>
    <thead>
      <tr>
        <th rowSpan={2} style={{ color: "#FFFFFF" }}>
          CLIENT
        </th>
        {templateName !== "roof" && (
          <>
            <th rowSpan={2} style={{ color: "#FFFFFF" }}>
              CAMPUS
            </th>
          </>
        )}
        <th rowSpan={2} style={styles.targetAudits}>
          TARGET AUDITS
        </th>
        {uniqueMonths.map((month) => (
          <React.Fragment key={month}>
            <th
              colSpan={2}
              style={{
                color: month === uniqueMonths[0] ? "#4DDCE5" : "#E002FD",
              }}
            >
              {month}
            </th>
          </React.Fragment>
        ))}
      </tr>
      <tr>
        {uniqueMonths.map((month) => (
          <React.Fragment key={month}>
            <th style={styles.completed}>COMPLETED</th>
            <th style={styles.failed}>FAILED ITEMS</th>
          </React.Fragment>
        ))}
      </tr>
    </thead>
  </>
);

export const AuditTable = ({ templateName, audits, targetValues }) => {
  const uniqueMonths = [...new Set(audits.map((audit) => audit.month))].sort();

  // Initialize grouped audits with targetValues so that each client/site is included
  const groupedAudits = Object.keys(targetValues).reduce((acc, key) => {
    const [audit_client, audit_site] = key.split("-");
    acc[key] = {
      audit_client: audit_client || "",
      audit_site: audit_site || "",
      target_audits: targetValues[key],
      data: {},
    };
    return acc;
  }, {});

  // Fill in audit data into grouped audits
  audits.forEach((audit) => {
    const key = `${audit.audit_client || ""}-${audit.audit_site || ""}`;
    if (groupedAudits[key]) {
      groupedAudits[key].data[audit.month] = {
        total_audits: audit.total_audits,
        total_failed_audits: audit.total_failed_audits,
      };
    }
  });
  // Sort the keys of groupedAudits by audit_client
  const sortedKeys = Object.keys(groupedAudits).sort((a, b) => {
    const clientA = groupedAudits[a].audit_client || "";
    const clientB = groupedAudits[b].audit_client || "";
    return clientA.localeCompare(clientB);
  });

  // Track row span for each client
  const rowSpanMap = sortedKeys.reduce((acc, key) => {
    const audit_client = groupedAudits[key].audit_client;
    acc[audit_client] = (acc[audit_client] || 0) + 1;
    return acc;
  }, {});

  let renderedClients = {};

  return (
    <Table className="audittable" striped bordered hover responsive>
      {renderTableHeader(templateName, uniqueMonths)}
      <tbody>
        {sortedKeys.map((key, index) => {
          const auditData = groupedAudits[key];
          const client = auditData.audit_client;

          const renderClientCell = !renderedClients[client];
          if (renderClientCell) {
            renderedClients[client] = true;
          }

          return (
            <tr key={index}>
              {templateName !== "roof" && (
                <>
                  {renderClientCell && (
                    <td rowSpan={rowSpanMap[client]} style={styles.client}>
                      {client}
                    </td>
                  )}
                  <td style={styles.campus}>{auditData.audit_site}</td>
                </>
              )}
              {templateName === "roof" && renderClientCell && (
                <td style={styles.client}>{client}</td>
              )}
              <td style={styles.targetAudits}>{auditData.target_audits}</td>
              {uniqueMonths.map((month) => {
                const auditMonthData = auditData.data[month] || {};
                return (
                  <React.Fragment key={month}>
                    <td style={styles.completed}>
                      {auditMonthData.total_audits !== undefined
                        ? auditMonthData.total_audits
                        : ""}
                    </td>
                    <td style={styles.failed}>
                      {auditMonthData.total_failed_audits !== undefined
                        ? auditMonthData.total_failed_audits
                        : ""}
                    </td>
                  </React.Fragment>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

// Define PropTypes to ensure valid data is passed in
AuditTable.propTypes = {
  templateName: PropTypes.string.isRequired,
  audits: PropTypes.arrayOf(
    PropTypes.shape({
      month: PropTypes.string.isRequired,
      audit_client: PropTypes.string,
      audit_site: PropTypes.string,
      total_audits: PropTypes.number.isRequired,
      total_failed_audits: PropTypes.number.isRequired,
    })
  ).isRequired,
};
