import React, { useEffect, useState } from "react";
import { Row, Col, Spinner, Button } from "react-bootstrap";
import { useFetchData } from "../components/utils/useFetchData";
import { UserTable } from "../layouts/UserTable";

export const UserList = ({ onEditUser, onDeleteUser, refresh }) => {
  const [page, setPage] = useState(1);
  const limit = 10;

  const {
    data: fetchedUserData,
    loading: fetchingUserData,
    error: fetchError,
    refetchData,
  } = useFetchData(`/auth/get_users?page=${page}&limit=${limit}}`);

  // Extract cameras and total count from the fetched data
  const users = fetchedUserData?.users || [];
  const totalCount = fetchedUserData?.total_count || 0;

  // Refetch data when the refresh prop changes
  useEffect(() => {
    if (refresh > 0) {
      refetchData();
    }
  }, [refresh, refetchData]);

  const handleNextPage = () => {
    if (page * limit < totalCount) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <>
      <Row className="mt-3">
        <Col>
          {fetchingUserData && <Spinner animation="border" variant="primary" />}
          <UserTable
            users={users}
            loading={fetchingUserData}
            fetchError={fetchError}
            onEditUser={onEditUser}
            onDeleteUser={onDeleteUser}
          />
        </Col>
      </Row>
      {/* Pagination Controls */}
      <Row className="mt-3">
        <Col className="d-flex justify-content-between align-items-center">
          <Button
            variant="secondary"
            onClick={handlePreviousPage}
            disabled={page === 1}
          >
            Previous
          </Button>
          <span>Page {page}</span>
          <Button
            variant="primary"
            onClick={handleNextPage}
            disabled={page * limit >= totalCount || fetchingUserData}
          >
            Next
          </Button>
        </Col>
      </Row>
    </>
  );
};
