import React, { useState, useCallback } from "react";
import { RoofImage } from "../components/RoofImage";
import { SelectDropdown } from "../components/SelectDropdown";
import { Button, Row, Col, Container, Alert, Spinner } from "react-bootstrap";
import { useJobCreation } from "../components/utils/useJobCreation";
import { useAerialImage } from "../components/utils/useAerialImage";
import JobCreationModal from "../components/modal/JobCreationModal";
import { AerialImageModal } from "../components/modal/AerialImageModal";
import { JobLinkModal } from "../components/modal/JobLinkModal";
import { RoofImageModal } from "../components/modal/RoofImageModal";

/**
 * The RoofByBuilding component allows users to select a building and manage related operations.
 *
 * It provides functionalities to:
 * - Display a dropdown for building selection.
 * - Fetch and display an aerial image of the selected building.
 * - Create a job related to the selected building.
 * - Display modals for aerial images, job creation, and job links.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Object} props.user - The authenticated user object.
 * @param {string} props.user.role - The role of the user.
 * @param {string} props.csrfToken - The CSRF token for secure API requests.
 *
 * @returns {JSX.Element} The rendered RoofByBuilding component.
 *
 * @example
 * // Usage example:
 * <RoofByBuilding user={user} csrfToken={csrfToken} />
 */

export const RoofByBuilding = ({ user, csrfToken }) => {
  // State to store selected building
  const [selectedBuilding, setSelectedBuilding] = useState({});
  // State to store selected cameras
  const [selectedCameras, setSelectedCameras] = useState([]);
  // State to store aerial image data
  const {
    aerialImage,
    handleFetchAerialImage,
    showImageModal,
    closeImageModal,
    loading: imageLoading,
    error: imageError,
  } = useAerialImage();

  // State to store job creation data
  const {
    handleCreateJob,
    jobUrl,
    showJobModal,
    setShowJobModal,
    showJobLinkModal,
    closeJobModal,
    closeJobLinkModal,
    loading,
    errorMessage,
  } = useJobCreation(selectedBuilding);

  const [showRoofImageModal, setShowRoofImageModal] = useState(false);
  const [enlargedImage, setEnlargedImage] = useState("");

  const handleBuildingChange = useCallback((building) => {
    setSelectedBuilding(building);
  }, []);

  const handleDataFetched = useCallback((data) => {
    setSelectedCameras(data);
  }, []);

  const handleRoofImageClick = (imageUrl) => {
    setEnlargedImage(imageUrl);
    setShowRoofImageModal(true);
  };
  return (
    <Container fluid>
      {/* SelectBuilding component */}
      <Row>
        <Col className="d-flex flex-row justify-content-center align-items-center flex-wrap">
          <SelectDropdown source="building" onChange={handleBuildingChange} />
          <Button
            onClick={() => handleFetchAerialImage(selectedBuilding.id)}
            disabled={!selectedBuilding.id || loading}
            variant="info"
          >
            {imageLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "View Aerial Image"
            )}
          </Button>

          {imageError && <Alert variant="danger">{imageError}</Alert>}
          <Button
            onClick={() => setShowJobModal(true)}
            disabled={
              !selectedBuilding.id ||
              user?.role !== "admin" ||
              !csrfToken ||
              loading
            }
            variant="warning"
          >
            Create Job
          </Button>
        </Col>
      </Row>

      {/* Error Alert */}
      {errorMessage && (
        <Row className="mt-2">
          <Col>
            <Alert variant="danger">{errorMessage}</Alert>
          </Col>
        </Row>
      )}

      {/* Building Name */}
      <Row>
        <Col>
          <h2>{selectedBuilding.name}</h2>
        </Col>
      </Row>

      {/* Render RoofImage component */}
      {selectedBuilding.id && (
        <RoofImage
          buildingId={selectedBuilding.id}
          onDataFetched={handleDataFetched}
          onImageClick={handleRoofImageClick}
        />
      )}

      {/* Modal Components */}
      <AerialImageModal
        show={showImageModal}
        handleClose={closeImageModal}
        aerialImage={aerialImage}
        buildingName={selectedBuilding.name}
      />
      <RoofImageModal
        show={showRoofImageModal}
        handleClose={() => setShowRoofImageModal(false)}
        imageUrl={enlargedImage}
      />

      <JobCreationModal
        show={showJobModal}
        handleClose={closeJobModal}
        handleCreateJob={handleCreateJob}
        loading={loading}
        selectedCameras={selectedCameras}
      />

      <JobLinkModal
        show={showJobLinkModal}
        handleClose={closeJobLinkModal}
        jobUrl={jobUrl}
      />
    </Container>
  );
};
