import { useState, useEffect } from "react";

/**
 * Custom hook to fetch and manage roof images for a specific building or camera.
 *
 * This hook provides state and handlers to:
 * - Fetch images based on a given building or camera ID.
 * - Handle pagination and loading states.
 * - Notify a callback function when data is fetched.
 *
 * @param {string} buildingId - The ID of the building for which to fetch images.
 * @param {string} cameraId - The ID of the camera for which to fetch images.
 * @param {function} onDataFetched - Callback function to be called with fetched data.
 *
 * @returns {Object} The returned object contains:
 * - `data` (Array): The fetched images data.
 * - `loading` (boolean): Whether the images are currently being fetched.
 * - `hasMore` (boolean): Whether there are more images to load for pagination.
 * - `loadMore` (function): Function to load more images (increment the page).
 *
 * @example
 * const { data, loading, hasMore, loadMore } = useRoofImages(buildingId, cameraId, onDataFetched);
 * if (loading) {
 *   return <div>Loading images...</div>;
 * }
 */

export const useRoofImages = (buildingId, cameraId, onDataFetched) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  /**
   * Reset page, data, and hasMore state when buildingId or cameraId changes.
   *
   * @function useEffect
   * @returns {void}
   */

  useEffect(() => {
    setPage(1);
    setData([]);
    setHasMore(true);
  }, [buildingId, cameraId]);

  /**
   * Fetch roof images when buildingId or cameraId changes, or when the page number changes.
   *
   * @function useEffect
   * @returns {void}
   */

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let query = "";
        let limit = 6;
        if (buildingId) {
          query = `/api/image_results/building?id=${encodeURIComponent(
            buildingId
          )}`;
          limit = 0;
        } else if (cameraId) {
          query = `/api/image_results/camera?id=${encodeURIComponent(
            cameraId
          )}&page=${page}&limit=6`;
        } else {
          console.warn("Neither buildingId nor cameraId provided.");
          return;
        }

        const response = await fetch(query);
        const result = await response.json();

        // Check if onDataFetched is a function before calling it
        if (typeof onDataFetched === "function") {
          onDataFetched(result.results);
        }
        if (cameraId && result.results.length < limit) {
          setHasMore(false);
        }
        setData((prevData) =>
          page === 1 ? result.results : [...prevData, ...result.results]
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    if ((buildingId || cameraId) && page > 0) {
      fetchData();
    }
  }, [buildingId, cameraId, page, onDataFetched]);

  /**
   * Load the next page of roof images.
   *
   * @function loadMore
   * @returns {void}
   */

  const loadMore = () => setPage((prevPage) => prevPage + 1);

  return { data, loading, hasMore, loadMore };
};
