import React from "react";
import { Modal, Image, Button } from "react-bootstrap";

/**
 * The AerialImageModal component displays a modal window showing an aerial image of a building.
 *
 * It accepts props to control its visibility, handle closing actions, and display relevant content.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Determines whether the modal is visible.
 * @param {function} props.handleClose - Function to handle the modal close action.
 * @param {string} props.aerialImage - URL or data source for the aerial image.
 * @param {string} props.buildingName - The name of the building to display in the modal title and image alt text.
 *
 * @returns {JSX.Element} The rendered AerialImageModal component.
 *
 * @example
 * // Usage example:
 * <AerialImageModal
 *   show={showModal}
 *   handleClose={handleModalClose}
 *   aerialImage={imageUrl}
 *   buildingName="Building A"
 * />
 */

export const AerialImageModal = ({
  show,
  handleClose,
  aerialImage,
  buildingName,
}) => {
  return (
    <Modal show={show} onHide={handleClose} size="xl" centered>
      <Modal.Header>
        <Modal.Title className="text-uppercase">
          Aerial Image of {buildingName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        {aerialImage ? (
          <Image
            src={aerialImage}
            alt={`Aerial view of ${buildingName}`}
            fluid
          />
        ) : (
          <p>No aerial image available</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
