import { useState, useCallback, useEffect } from "react";
import { useFetchData } from "./useFetchData";

/**
 * Custom hook to manage fetching and displaying aerial images for a building.
 *
 * This hook provides state and handlers to:
 * - Fetch an aerial image for a building based on its ID.
 * - Manage the visibility of a modal to display the image.
 *
 * @returns {Object} The returned object contains:
 * - `aerialImage` (string|null): The URL of the aerial image.
 * - `showImageModal` (boolean): Whether the modal to show the aerial image is visible.
 * - `handleFetchAerialImage` (function): Function to fetch the aerial image for a given building.
 * - `closeImageModal` (function): Function to close the aerial image modal.
 *
 * @example
 * const {
 *   aerialImage,
 *   showImageModal,
 *   handleFetchAerialImage,
 *   closeImageModal,
 * } = useAerialImage();
 */

export const useAerialImage = () => {
  const [aerialImage, setAerialImage] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [buildingId, setBuildingId] = useState(null);

  // Only invoke useFetchData when the URL is valid
  const fetchUrl = buildingId ? `/api/building_image?id=${buildingId}` : null;
  const { data, loading, error } = useFetchData(fetchUrl);

  useEffect(() => {
    if (data && data.presigned_url) {
      setAerialImage(data.presigned_url);
      setShowImageModal(true);
    } else if (error) {
      setShowImageModal(false);
    }
  }, [data, error]);

  /**
   * Fetches the aerial image for the given building ID.
   *
   * @param {string} buildingId - The ID of the building for which to fetch the aerial image.
   * @returns {void}
   */
  const handleFetchAerialImage = useCallback((buildingId) => {
    setBuildingId(null); // Reset buildingId to avoid stale data issues
    setAerialImage(null);
    setShowImageModal(false);
    setTimeout(() => {
      setBuildingId(buildingId);
    }, 100); // Delay by 100ms to allow state reset
  }, []);

  /**
   * Closes the aerial image modal.
   *
   * @function closeImageModal
   * @returns {void}
   */

  const closeImageModal = useCallback(() => {
    setShowImageModal(false);
    setAerialImage(null);
    setBuildingId(null); // Reset buildingId to avoid stale data issues
  }, []);

  return {
    aerialImage,
    showImageModal,
    handleFetchAerialImage,
    closeImageModal,
    loading,
    error,
  };
};
