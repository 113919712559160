import React from "react";
import { useAuth } from "../components/auth/AuthProvider";
import { Navbar, Nav, Button, Container } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * The Header component renders the navigation bar with links and authentication controls.
 *
 * It displays different navigation options based on the user's authentication status and role.
 * - If the user is authenticated, it shows links to Home, Roof, Camera, and Test pages.
 * - If the user has an admin role, it also shows the Register link.
 * - Provides a Logout button for authenticated users.
 * - Shows a Login button for unauthenticated users unless they are on the login page.
 *
 * @component
 * @returns {JSX.Element} The rendered Header component.
 *
 * @example
 * // Renders the Header component within the application
 * <Header />
 */

export const Header = () => {
  const { isAuthenticated, user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Logs out the current user and redirects to the login page.
   *
   * @async
   * @function handleLogout
   * @returns {Promise<void>}
   */

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (err) {
      console.error("Logout failed", err);
    }
  };

  const handleProfileClick = () => {
    navigate("/profile"); // Adjust the path to your profile page route
  };

  return (
    <Navbar
      bg="dark"
      variant="dark"
      expand="lg"
      className="navbar"
      collapseOnSelect="true"
    >
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>RPS Dashboard</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {isAuthenticated && (
              <>
                {user?.client === "reliable plumbing services" && (
                  <LinkContainer to="/home">
                    <Nav.Link>Home</Nav.Link>
                  </LinkContainer>
                )}
                <LinkContainer to="/roof">
                  <Nav.Link>Roof</Nav.Link>
                </LinkContainer>
                {user?.client === "reliable plumbing services" && (
                  <LinkContainer to="/camera">
                    <Nav.Link>Camera</Nav.Link>
                  </LinkContainer>
                )}

                {user?.client === "reliable plumbing services" && (
                  <LinkContainer to="/audit">
                    <Nav.Link>Audit</Nav.Link>
                  </LinkContainer>
                )}
              </>
            )}

            {/* Show Register link only if the user is admin */}
            {user?.role === "superuser" && (
              <LinkContainer to="/users">
                <Nav.Link>Users</Nav.Link>
              </LinkContainer>
            )}
          </Nav>

          <Nav>
            {isAuthenticated ? (
              <div className="nav-authenticated">
                <Button
                  variant="link"
                  onClick={handleProfileClick}
                  className="p-0 m-0 me-2"
                  title="Profile"
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    color: "blue",
                    minWidth: "0",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faUser}
                    className="me-2"
                    color="blue"
                  />
                </Button>
                <Navbar.Text className="me-3">
                  Welcome, {user?.username || ""}
                </Navbar.Text>
                <Button variant="outline-light" onClick={handleLogout}>
                  Logout
                </Button>
              </div>
            ) : (
              // Only show Login link if not on the login page
              location.pathname !== "/login" && (
                <LinkContainer to="/login">
                  <Button variant="outline-light">Login</Button>
                </LinkContainer>
              )
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
