import React, { useState } from "react";
import { Container, Row, Col, Button, Alert, Spinner } from "react-bootstrap";
import { useObjectData } from "../components/utils/useObjectData";
import {
  addUser,
  updateUser,
  deleteUser,
} from "../components/utils/userService";
import { useCsrfToken } from "../components/utils/useCsrfToken";
import { UserList } from "../layouts/UserList";
import { UserModal } from "../components/modal/UserModal";

export const UserPage = () => {
  const [showUserModal, setShowUserModal] = useState(false);
  const [refreshUserList, setRefreshUserList] = useState(0);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const { csrfToken, csrfLoading, csrfError } = useCsrfToken();
  const initialUserState = {
    id: null,
    username: "",
    password: "",
    role: "",
  };

  const {
    objectData: userData,
    setObjectData: setUserData,
    handleChange,
  } = useObjectData(initialUserState);

  const handleAddUser = () => {
    setIsDelete(false);
    setIsEditing(false);
    setShowUserModal(true);
  };

  const handleDeleteUser = (user) => {
    setIsDelete(true);
    setUserData(user);
    setIsEditing(false);
    setShowUserModal(true);
  };

  const handleEditUser = (user) => {
    setUserData(user);
    setIsEditing(true);
    setShowUserModal(true);
    setIsDelete(false);
  };

  const onUserUpdate = () => {
    setRefreshUserList((prev) => prev + 1);
  };

  const handleModalClose = () => {
    setShowUserModal(false);
    setUserData(initialUserState);
    setAlertMessage("");
    setIsDelete(false);
    setIsEditing(false);
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    setLoading(true);
    try {
      let response;
      if (isEditing) {
        response = await updateUser(csrfToken, userData.id, userData);

        setAlertMessage(`User "${userData.username}" updated successfully.`);
      } else if (isDelete) {
        response = await deleteUser(csrfToken, userData.id);

        setAlertMessage(`User "${userData.username}" deleted successfully.`);
      } else {
        response = await addUser(csrfToken, userData);
        setAlertMessage(`User "${userData.username}" added successfully.`);
      }
      onUserUpdate();
      handleModalClose();
    } catch (error) {
      const errorMessage =
        typeof error.message === "object"
          ? JSON.stringify(error.message)
          : error.message;

      setAlertMessage(errorMessage || "An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="userpage">
      {csrfLoading && <Spinner animation="border" />}
      {csrfError && <Alert variant="danger">{csrfError}</Alert>}
      <Row className="mt-3">
        <Col>
          <h1>User Management</h1>
        </Col>
      </Row>

      <Row>
        <Col className="d-flex justify-content-end align-items-center">
          <Button variant="primary" onClick={handleAddUser}>
            Add User
          </Button>
        </Col>
      </Row>

      <Row>
        <Col>
          <UserList
            onEditUser={handleEditUser}
            onDeleteUser={handleDeleteUser}
            refresh={refreshUserList}
          />
        </Col>

        <UserModal
          show={showUserModal}
          title={
            isEditing ? "Edit User" : isDelete ? "Delete User" : "Add User"
          }
          handleClose={handleModalClose}
          onSubmit={handleSubmit}
          message={alertMessage}
          userData={userData}
          setUserData={setUserData}
          handleChange={handleChange}
          isEditing={isEditing}
          isDelete={isDelete}
          loading={loading}
        />
      </Row>
    </Container>
  );
};
