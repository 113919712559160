import { useState } from "react";

export const useObjectData = (initialState = {}) => {
  const [objectData, setObjectData] = useState(initialState);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setObjectData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return {
    objectData,
    setObjectData,
    handleChange,
  };
};
