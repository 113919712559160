import React, { createContext, useState, useEffect, useContext } from "react";
import { useCsrfToken } from "../utils/useCsrfToken";

/**
 * Authentication context to provide authentication state and functions throughout the app.
 *
 * @type {React.Context}
 */

// Create a context for authentication
const AuthContext = createContext();

/**
 * Custom hook to access the authentication context.
 *
 * @returns {Object} Authentication context value including:
 * - `isAuthenticated`: Boolean indicating if the user is authenticated.
 * - `user`: Object containing user data.
 * - `loading`: Boolean indicating if the authentication status is loading.
 * - `login`: Function to log in the user.
 * - `logout`: Function to log out the user.
 */

// Export useAuth hook to access authentication state in any component
export const useAuth = () => useContext(AuthContext);

/**
 * AuthProvider component that wraps its children with authentication context.
 *
 * It manages authentication state, user data, and provides login and logout functions.
 * It also handles CSRF token retrieval and manages loading and error states.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {React.ReactNode} props.children - The child components that require authentication context.
 *
 * @returns {JSX.Element} The rendered AuthProvider component.
 *
 * @example
 * // Wrap your application with AuthProvider
 * <AuthProvider>
 *   <App />
 * </AuthProvider>
 */

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const { csrfToken, csrfError, csrfLoading } = useCsrfToken();

  // Fetch user authentication status and user data on component mount
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch("/auth/check-auth", {
          credentials: "include",
        });
        const result = await response.json();

        if (response.ok) {
          setIsAuthenticated(true);

          setUser(result.user);
        } else {
          setIsAuthenticated(false);
          setUser(null); // Clear user data if authentication fails
        }
      } catch (error) {
        setIsAuthenticated(false);
        setUser(null); // Clear user data if authentication fails
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  /**
   * Logs in the user by updating authentication state and user data.
   *
   * @function
   * @param {Object} userData - The user data object obtained after successful authentication.
   * @returns {void}
   */

  const login = (userData) => {
    setIsAuthenticated(true);
    setUser(userData);
  };

  /**
   * Logs out the current user by clearing authentication state and user data.
   *
   * Sends a POST request to the server to invalidate the user session.
   *
   * @async
   * @function
   * @returns {Promise<void>}
   */

  const logout = async () => {
    if (!csrfToken || csrfLoading || csrfError) {
      console.error("CSRF token not available for logout request");
      return; // Return early if CSRF token is not available or loading or there is an error with the token request
    }

    try {
      // Log CSRF token before sending the request

      const response = await fetch("/auth/logout", {
        method: "POST",
        headers: {
          "X-CSRFToken": csrfToken,
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      if (response.ok) {
        // Clear user session on the client side
        setIsAuthenticated(false);
        setUser(null);
      } else {
        console.log("Failed to log out");
      }
    } catch (error) {
      console.log("Failed to log out", error);
    }
  };

  /**
   * Updates user data and syncs it with the server.
   *
   * @param {Object} updatedData - The updated user data (e.g., { email, password }).
   * @returns {Promise<void>}
   */
  const updateUser = async (updatedData) => {
    if (!csrfToken || csrfLoading || csrfError) {
      console.error("CSRF token not available for update request");
      return;
    }

    try {
      const response = await fetch("/auth/update-profile", {
        method: "PUT",
        headers: {
          "X-CSRFToken": csrfToken,
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(updatedData),
      });

      if (response.ok) {
        const result = await response.json();
        setUser(result.user); // Update local user data with response from server
        // console.log("User profile updated successfully");
      } else {
        console.error("Failed to update user profile");
      }
    } catch (error) {
      console.error("Error updating user profile:", error);
    }
  };

  // Handle CSRF loading or errors
  if (csrfLoading) {
    return <div>Loading CSRF token...</div>;
  }

  if (csrfError) {
    return <div>Error loading CSRF token: {csrfError}</div>;
  }

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, user, loading, login, logout, updateUser }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};
