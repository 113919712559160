import { useState, useCallback } from "react";
import { useCsrfToken } from "../utils/useCsrfToken";

/**
 * Custom hook to manage the creation of jobs in AroFlo.
 *
 * This hook provides state and handlers to:
 * - Create a new job using provided job details.
 * - Manage modals for job creation and displaying the job link.
 *
 * @param {string} csrfToken - The CSRF token for secure requests.
 * @param {Object} selectedBuilding - The building object selected for job creation.
 * @param {string} selectedBuilding.id - The ID of the selected building.
 *
 * @returns {Object} The returned object contains:
 * - `handleCreateJob` (function): Function to create a new job with provided details.
 * - `jobUrl` (string|null): The URL of the created job, or `null` if not available.
 * - `showJobModal` (boolean): Whether the job creation modal is visible.
 * - `setShowJobModal` (function): Function to set the visibility of the job creation modal.
 * - `showJobLinkModal` (boolean): Whether the job link modal is visible.
 * - `setShowJobLinkModal` (function): Function to set the visibility of the job link modal.
 * - `closeJobModal` (function): Function to close the job creation modal.
 * - `closeJobLinkModal` (function): Function to close the job link modal.
 * - `loading` (boolean): Indicates if a job creation operation is in progress.
 *
 * @example
 * const {
 *   handleCreateJob,
 *   jobUrl,
 *   showJobModal,
 *   setShowJobModal,
 *   showJobLinkModal,
 *   setShowJobLinkModal,
 *   closeJobModal,
 *   closeJobLinkModal,
 *   loading,
 * } = useJobCreation(csrfToken, selectedBuilding);
 */

export const useJobCreation = (selectedBuilding) => {
  const { csrfToken, csrfLoading, csrfError } = useCsrfToken();
  const [loading, setLoading] = useState(false);
  const [jobUrl, setJobUrl] = useState(null);
  const [showJobModal, setShowJobModal] = useState(false);
  const [showJobLinkModal, setShowJobLinkModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  /**
   * Handles the creation of a new job.
   *
   * Sends a request to the server to create a job in AroFlo using the provided job details.
   *
   * @async
   * @function handleCreateJob
   * @param {Object} jobDetails - The details of the job to be created.
   * @param {string} jobDetails.taskname - The name of the task.
   * @param {string} jobDetails.description - The description of the job.
   * @param {string} jobDetails.duedate - The due date of the job in `YYYY-MM-DD` format.
   * @returns {Promise<boolean>} A boolean indicating whether the job was created successfully.
   */

  const handleCreateJob = useCallback(
    async (jobDetails) => {
      if (csrfLoading) {
        setErrorMessage("CSRF token is still loading. Please wait.");
        return false;
      }
      if (!csrfToken) {
        setErrorMessage(
          csrfError || "CSRF token not available. Please try again later."
        );
        return false;
      }

      setLoading(true);

      try {
        const response = await fetch("/api/aroflo/create_task", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
          body: JSON.stringify({
            buildingId: selectedBuilding.id,
            taskname: jobDetails.taskname,
            description: jobDetails.description,
            duedate: jobDetails.duedate,
          }),
          credentials: "include",
        });

        if (response.ok) {
          const result = await response.json();
          setJobUrl(result.url);
          setShowJobModal(false);
          setShowJobLinkModal(true);
          setErrorMessage("");
          return true;
        } else {
          const responseError = await response.text();
          if (response.status === 403) {
            setErrorMessage(
              "User does not have the required role to perform this action."
            );
          } else {
            setErrorMessage(
              response.status === 403
                ? "User does not have the required role to perform this action."
                : responseError || "An error occurred while creating the job."
            );
            return false;
          }
        }
      } catch (error) {
        setErrorMessage("An unexpected error occurred: " + error.message);
        return false;
      } finally {
        setLoading(false);
      }
    },
    [csrfToken, csrfLoading, csrfError, selectedBuilding.id]
  );

  return {
    handleCreateJob,
    jobUrl,
    showJobModal,
    setShowJobModal,
    showJobLinkModal,
    setShowJobLinkModal,
    closeJobModal: useCallback(() => setShowJobModal(false), []),
    closeJobLinkModal: useCallback(() => setShowJobLinkModal(false), []),
    loading,
    errorMessage,
  };
};
