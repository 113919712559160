import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = ({ statusCode, message }) => {
  let errorMessage;
  let defaultLink = "/home"; // Default link to redirect users

  // Handle different error codes
  switch (statusCode) {
    case 401:
      errorMessage = "401 - Unauthorized";
      message = message || "You need to log in to access this page.";
      defaultLink = "/login"; // Redirect to login page
      break;
    case 403:
      errorMessage = "403 - Forbidden";
      message = message || "You do not have permission to view this page.";
      break;
    case 404:
      errorMessage = "404 - Page Not Found";
      message = message || "The page you are looking for does not exist.";
      break;
    default:
      errorMessage = "An unknown error occurred";
      message = message || "Something went wrong. Please try again later.";
  }

  return (
    <div>
      <h1>{errorMessage}</h1>
      <p>{message}</p>
      <Link to={defaultLink}>Go back</Link>
    </div>
  );
};

export default ErrorPage;
