import React, { useEffect, useState } from "react";
import Select from "react-select";

/**
 * Renders a select dropdown for selecting buildings or cameras.
 *
 * This component fetches options from an API based on the `source` prop (either "building" or "camera"),
 * and provides a dropdown selection for the user. It optionally includes a "Not Assigned" option.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.source - The type of dropdown (either "building" or "camera").
 * @param {function} props.onChange - Callback function to pass the selected value to the parent component.
 * @param {Object} props.value - The controlled value for the dropdown (selected option).
 * @param {boolean} [props.allowNotAssigned=false] - Whether to allow a "Not Assigned" option.
 *
 * @returns {JSX.Element} The rendered select dropdown component.
 *
 * @example
 * <SelectDropdown
 *   source="building"
 *   onChange={handleBuildingChange}
 *   value={{ value: 'building_1', label: 'Building 1' }}
 *   allowNotAssigned={true}
 * />
 */

export const SelectDropdown = ({
  source,
  onChange,
  value,
  allowNotAssigned = false,
}) => {
  // State to store the list of options
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false); // Track loading state

  /**
   * Fetches the list of options from the API based on the source type.
   *
   * @function useEffect
   * @returns {void}
   */

  useEffect(() => {
    const fetchOptions = async () => {
      setLoading(true);
      try {
        const endpoint = `/api/list_results/${source}`; // Dynamically construct the route based on the source (building or camera)
        const response = await fetch(endpoint); // API endpoint for fetching cameras
        const data = await response.json(); // Assume the API returns a JSON array of camera names

        // Format options for React-Select: { value: option.id, label: option.building_name/camera_name }
        const formattedOptions = data.results.map((option) => ({
          value: option.id,
          label:
            source === "building"
              ? `${option.building_name} (bld ${option.building_number})`
              : source === "client"
              ? option.client_name
              : option.id,
        }));

        // Optionally add a "Not Assigned" option
        if (allowNotAssigned) {
          formattedOptions.unshift({
            value: null,
            label: "Not Assigned",
          });
        }

        setOptions(formattedOptions); // Set camera list in state
      } catch (error) {
        console.error("Error fetching camera list:", error);
      } finally {
        setLoading(false); // Reset loading state after fetching data or error
      }
    };

    fetchOptions(); // Fetch cameras when the component mounts
  }, [source, allowNotAssigned]); // Empty dependency array ensures this runs once

  /**
   * Handles the selection change in the dropdown.
   *
   * Calls the `onChange` callback with the selected option's value and label.
   *
   * @function handleChange
   * @param {Object} selectedOption - The selected option from the dropdown.
   * @param {string|number|null} selectedOption.value - The value of the selected option.
   * @param {string} selectedOption.label - The label of the selected option.
   * @returns {void}
   */

  const handleChange = (selectedOption) => {
    // Call the onChange callback with the selected option's value and label
    onChange({
      id: selectedOption.value,
      name: selectedOption.label,
    });
  };

  return (
    <Select
      className={`dropdown-option-${source}`}
      options={options} // List of options
      isSearchable={true} // Enable search
      onChange={handleChange} // Handle selection change
      value={value} // Controlled value to reset dropdown selection
      placeholder={`Select ${source}`} // Placeholder text
      isDisabled={loading}
    />
  );
};
