import React, { useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { useFetchData } from "../utils/useFetchData";
import { SelectDropdown } from "../SelectDropdown";

/**
 * The UserForm component provides a reusable form for adding or editing a user.
 *
 * It includes input fields for user details such as username, email, ID, password,
 * and building location. It handles form submission by sending a POST or PUT request to the server,
 * manages CSRF tokens for security, and provides user feedback upon success or failure.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.userData - The camera data to be edited or initial empty state for adding.
 * @param {function} props.setUserData - Function to update camera data in state.
 * @param {function} props.handleSubmit - Function to handle form submission.
 * @param {boolean} props.loading - Indicates if the form submission is in progress.
 * @param {boolean} props.isEditing - Indicates if the form is for editing (true) or adding (false).
 * @returns {JSX.Element} The rendered CameraForm component.
 *
 * @example
 * // Use the UserForm component in your application
 * <UserForm
 *   userData={userData}
 *   setUserData={setUserData}
 *   handleSubmit={handleSubmit}
 *   loading={loading}
 *   isEditing={true}
 * />
 */

export const UserForm = ({
  userData,

  handleChange,
  handleClientChange,
  selectedClient,
  onFormSubmit,
  isEditing,
  loading,
}) => {
  return (
    <Container className="user-form">
      <Form onSubmit={onFormSubmit}>
        <Row>
          <Form.Group as={Col} className="mb-2" controlId="userName">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              name="username"
              placeholder="Username"
              value={userData.username || ""}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </Row>
        <Form.Group as={Col} className="mb-2" controlId="userPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            name="password"
            placeholder={
              isEditing ? "Enter new password if changing" : "Password"
            }
            value={userData.password || ""}
            onChange={handleChange}
            required={!isEditing} // Required if adding a new user
          />
        </Form.Group>

        <Form.Group as={Col} className="mb-2" controlId="userEmail">
          <Form.Label>User Email</Form.Label>
          <Form.Control
            type="text"
            name="email"
            placeholder="Email Address"
            value={userData.email || ""}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group as={Col} className="mb-2" controlId="userClient">
          <Form.Label>Client</Form.Label>
          <SelectDropdown
            source="client"
            value={
              selectedClient.id
                ? { value: selectedClient.id, label: selectedClient.name }
                : { value: null, label: "Select client" }
            }
            allowNotAssigned={true}
            onChange={handleClientChange}
          />
        </Form.Group>

        <Row>
          <Form.Group as={Col} className="mb-2" controlId="userAccess">
            <Form.Label>User Access</Form.Label>
            <Form.Select
              name="role"
              value={userData.role || ""}
              onChange={handleChange}
            >
              <option value="">Select Access Type</option>
              <option value="admin">Admin</option>
              <option value="user">User</option>
              {/* Add more options as needed */}
            </Form.Select>
          </Form.Group>
        </Row>

        <Button variant="primary" type="submit" disabled={loading}>
          {loading ? "Loading..." : isEditing ? "Update User" : "Add User"}
        </Button>
      </Form>
    </Container>
  );
};
